.project-title{
    display: flex;
    justify-content: left;
    gap: 1rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
}

.project-title h1{
    font-size: 4rem;
    font-weight: 500;
    color: #f9fafb;
    margin: 0;
    padding: 0;
}

.description-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 4rem;
}

.description-single{
    font-size: large;
}

.info-title{
    font-size: 1.5rem;
    padding-bottom: 1rem;
}

.info-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 4rem;
}

.info-techs{
    padding-right: 20%;
}

.image img{
    max-width: 100%; /* Images will not exceed the width of their container */
    height: auto;
}