.projects-title{
    display: flex;
    justify-content: left;
    gap: 1rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
}

.projects-title h1{
    font-size: 4rem;
    font-weight: 500;
    color: #f9fafb;
    margin: 0;
    padding: 0;
}

.projects-title h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: #a0a0a0;
    margin: 0;
    padding: 0;
    padding-top: 2rem;
}

.projects-description p{
    color: #a0a0a0;
    font-size: 1.25rem;
}

.projects-list-title{
    padding-top: 4rem;
    padding-bottom: .5rem;
    padding-left:.5rem;
}

.projects-list-title h1{
    font-size: 2rem;
}

.projects-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 8rem;
}