
.project-card-container{
    position: relative;
    background-color: var(--foreground-dark-color);
    height: 30rem;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.project-card-body{
    height: 100%;
    width: 100%;
    float: left;
    text-align: right;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.project-card-body h1{
    font-size: 1.5rem;
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 1rem;
}

.project-card-diagonal-line {
    mix-blend-mode: difference;
    position: absolute;
    top: 45%;
    width: 130%;
    border-bottom: 2px solid white;
    transform: rotate(41.65deg);
    transform-origin: left top;
    -webkit-transform: rotate(41.65deg);
    -moz-transform: rotate(41.65deg);
    -ms-transform: rotate(41.65deg);
    -o-transform: rotate(41.65deg);
    z-index: 2;
}

.project-card-diagonal-section{
    position: absolute;
    height: 50%;
    bottom: 0;
    width: 100%;
    overflow: hidden;
}

.project-card-diagonal-section img{
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-image: linear-gradient(to bottom right, transparent 49.9%,  var(--foreground-dark-color) 50.1%);
}

.project-card-right-body p {
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.project-card-date-container{
    position: absolute;
    left: 2rem;
    bottom:17.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: .25rem;
}

