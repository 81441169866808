:root{
  /* Background & Foreground Colors*/
  --background-color: #1c1c1c;
  --foreground-dark-color: #282828;
  --foreground-light-color: #323232;
  --foreground-highlight-color: #404040;
  /* Font Colors */
}

.App {
  text-align: center;
  background-color: var(--background-color);
}

h1, h2, h3, h4, h5, h6{
  color: rgb(237, 237, 237);
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

p{
  color: #a0a0a0;
  font-size: .85rem;
  padding: 0;
  margin: 0;
}

a, a:visited, a:hover, a:active{
  color: inherit;
  text-decoration: none;
  &:hover{
      text-decoration: none;
  }
}

body {
  background-color: #1c1c1c;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
  padding: 0 1rem;
}