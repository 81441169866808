*{
    box-sizing: border-box;
}

header{
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1120px;
    height: 3rem;
    margin: 0px auto;
    margin-bottom: 2rem;
    border-bottom: 1px solid #282828;
}

#header-rightSection{
    display: flex;
    gap: .75rem;
    align-items: center;
}

.link a{
    display: block;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}